<!--工位类模板-->
<template>
    <div class="room">
        <div class="search_box">
            <el-button type="primary" plain size="medium" @click="add">新增模板</el-button>
        </div>

        <!-- 表格 -->
        <el-table :data="tableData" border :header-cell-style="{
                background: '#FAFAFA',
                color: '#000000',
            }">
            <el-table-column label="模板名称" align="center" prop="templateName">
            </el-table-column>
            <el-table-column label="单价单位" align="center" prop="pricingTypeName">
            </el-table-column>
            <el-table-column label="默认取消时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.orderCancelNum + scope.row.orderCancelUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="默认保洁时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.cleanNum + scope.row.cleanUnitName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="押金" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.depositNum + (scope.row.depositUnit == 1 ? '个' : '') + scope.row.depositUnitName
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="medium" @click="edit(scope.row)">编辑</el-button>
                    <el-button type="text" size="medium" @click="storeConfig(scope.row)">仓型配置</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 编辑弹框 -->
        <el-dialog :visible.sync="infoShow" :title="title" :close-on-click-modal="false" width="40%" center
            @close="close">
            <el-form :model="baseForm" :rules="rules" ref="baseForm" label-width="200px">
                <el-form-item label="模板名称" prop="templateName">
                    <el-input v-model="baseForm.templateName" placeholder="请输入模板名称"></el-input>
                </el-form-item>
                <el-form-item label="单位单价" prop="pricingType">
                    <el-select v-model="baseForm.pricingType" placeholder="请选择单价单位" @change="reload">
                        <el-option v-for="item in ObjToOpt(priceTypeObj)" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单默认取消时间" prop="orderCancelNum">
                    <el-input placeholder="请输入取消时间" v-model="baseForm.orderCancelNum" class="input-with-select"
                        @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                ? false
                : e.returnValue)
                " @input="numberInput">
                        <el-select v-model="baseForm.orderCancelUnit" slot="append" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(timeUnit)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="默认保洁时间" prop="cleanNum">
                    <el-input placeholder="请输入保洁时间" v-model="baseForm.cleanNum" class="input-with-select"
                        @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                ? false
                : e.returnValue)
                " @input="numberInput">
                        <el-select v-model="baseForm.cleanUnit" slot="append" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(timeUnit)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="订单押金" prop="depositNum">
                    <el-input placeholder="请输入月数或金额" v-model="baseForm.depositNum" class="input-with-select"
                        @keydown.native="(e) =>
            (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                ? false
                : e.returnValue)
                " @input="numberInput">
                        <el-select v-model="baseForm.depositUnit" slot="append" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(depositUnit)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit(1)">确定</el-button>
            </div>
        </el-dialog>
        <!-- 仓型配置弹框 -->
        <el-dialog :visible.sync="storeShow" :title="'仓型配置'" :close-on-click-modal="false" width="60%" center
            @close="close">
            <el-form :model="baseForm" :rules="rules" ref="baseForm1" label-width="200px">
                <el-form-item label="模板名称" prop="templateName">
                    <el-input v-model="baseForm.templateName" disabled></el-input>
                </el-form-item>
                <el-form-item label="仓型图片" prop="storagePic">
                    <div class="upload-btn" @click="onUpload(1, 1)">
                        <img v-if="baseForm.storagePicUrl" :src="baseForm.storagePicUrl" class="upload-img">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </div>
                    <div style="color: red;font-size: 12px;margin: 10px 0;">建议上传60*60像素，500kb以内的图片</div>
                </el-form-item>

                <el-form-item label="体积范围" prop="volumeRange">
                    <el-input v-model="baseForm.volumeRange" placeholder="输入体积范围">
                        <span slot="append">m³</span>

                    </el-input>
                </el-form-item>
                <el-form-item label="仓型介绍" prop="storageInfo" class="long-width">
                    <quill-editor v-model="baseForm.storageInfo" ref="myQuillEditor" :options="editorOption"
                        @change="onEditorChange1($event)">
                    </quill-editor>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="plain" @click="cancel">取消</el-button>
                <el-button type="primary" @click="onSubmit(2)">确定</el-button>
            </div>
        </el-dialog>
        <!-- 图片上传组件 -->
        <UpLoad @fileIds="getFileIds" :maxlimit="maxlimit" ref="up" :fileType="fileType"></UpLoad>
    </div>
</template>
<script>
import { priceTypeObj, timeUnit, depositUnit } from "@/db/objs";
import { rules } from "@/db/rules";
import { ObjToOpt } from "@/utils/utils";
import { addService, serviceList, editService,serviceInfo } from '@/api/setting/service.js'
import UpLoad from "@/components/upload";
import {
    getFileList
} from "@/api/common";

import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import * as Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);
export default {
    data() {
        return {
            priceTypeObj,
            ObjToOpt,
            timeUnit,
            rules,
            depositUnit,
            tableParams: {
                serviceTemplateType: 4,
            },
            tableData: [],
            infoShow: false,
            storeShow: false,
            title: "新增模板",
            baseForm: {
                serviceTemplateType: 4,
                orderCancelUnit: 1,
                cleanUnit: 1,
                depositUnit: 1
            },
            fileType: 1,
            upType: "",
            editorOption: {
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            ['blockquote', 'code-block'], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            [{ direction: 'rtl' }], // 文本方向
                            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            [{ align: [] }], // 对齐方式
                            ['clean'], // 清除文本格式
                            ['image', 'video'] // 链接、图片、视频
                        ],
                        handlers: {
                            'image': (value) => {
                                if (value) {
                                    this.onUpload(1, 3);
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    },
                    // 调整图片大小
                    imageResize: {
                        displayStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                        },
                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                    }
                },
                placeholder: '请填写正文'
            },
        };
    },
    components: { UpLoad, quillEditor },
    computed: {

        maxlimit() {
            if (this.fileList && this.fileList.length > 0) {
                return 5 - this.fileList.length
            } else {
                return 5
            }
        }
    },
    // 监听属性  
    watch: {
        fileList(val) {

            let arr = [];
            if (val && val.length > 0) {
                for (let i of val) {
                    arr = [...arr, i.attId]
                }
            }
            this.params.imageIds = arr.toString()

        },
    },
    mounted() {
        this.getTableList();
    },
    methods: {
        // 获取服务模板列表
        getTableList() {
            serviceList(this.tableParams).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data;
                }
            });
        },
        close() {
            this.reset()
            this.$refs['baseForm'].clearValidate();
        },
        reset() {
            this.baseForm = {
                serviceTemplateType: 4,
                templateName: '',
                pricingType: '',
                orderCancelNum: '',
                cleanNum: '',
                depositNum: '',
                orderCancelUnit: 1,
                cleanUnit: 2,
                depositUnit: 1
            }
        },
        // 【监听】表格重载
        reload() {
            this.$refs['baseForm'].clearValidate();
            this.getTableList();
        },


        add() {
            this.reset()
            this.infoShow = true;
            this.title = "新增模板";
        },
        edit(row) {
            this.infoShow = true;
            this.title = "编辑模板";
            serviceInfo({templateId:row.templateId}).then(res=>{
                this.baseForm = res.data;
            })
        },
        numberInput(value) {
            if (value.length > 8) { this.baseForm.experience = value.slice(0, 8) }
        },
        onSubmit(type) {
            if (type == 1) {
                this.$refs.baseForm.validate((valid) => {
                    if (valid) {
                        // 表单验证通过，执行提交逻辑
                        if (this.baseForm.templateId) {
                            editService(this.baseForm).then(res => {
                                this.$message.success(res.message);
                                this.reload();
                            })
                        } else {
                            addService(this.baseForm).then(res => {
                                this.$message.success(res.message);
                                this.reload();
                            })
                        }

                        this.infoShow = false;
                    } else {
                        // 表单验证失败，提示用户
                        // this.$message.error("表单验证失败，请检查输入！");
                        return false;
                    }
                });
            }else{
                this.$refs.baseForm1.validate((valid) => {
                    if (valid) {
                        // 表单验证通过，执行提交逻辑
                        if (this.baseForm.templateId) {
                            editService(this.baseForm).then(res => {
                                this.$message.success(res.message);
                                this.reload();
                            })
                        } 

                        this.storeShow = false;
                    } else {
                        // 表单验证失败，提示用户
                        // this.$message.error("表单验证失败，请检查输入！");
                        return false;
                    }
                });
            }

        },
        storeConfig(row) {
            this.storeShow = true;
            serviceInfo({templateId:row.templateId}).then(res=>{
 
                this.baseForm = res.data;
            })
        },
   
        cancel() {
            this.storeShow = false;
            this.$refs.baseForm.clearValidate()
            this.baseForm = {

            }
        },
        // 【监听】文件上传
        onUpload(fileType, upType) {
            this.fileType = fileType;
            this.upType = upType;
            this.$refs.up.onUpload();
        },
        // 【监听】富文本内容改变事件
        onEditorChange1({ quill, html, text }) {
            this.baseForm.storageInfo = html
        },
        // 【监听】获取上传文件ID集合
        getFileIds(fileIds) {
            let data = {
                fileIds
            }
            getFileList(data).then(res => {
                if (res.isSuccess == "yes") {
                    // 缩略图
                    if (this.upType == 1) {
                        this.baseForm.storagePic = fileIds;
                        this.baseForm.storagePicUrl = res.data[0].url;
                    }
                    // 主图
                    else if (this.upType == 2) {
                        this.baseForm.imageIds = fileIds;
                        this.fileList = [...this.fileList, ...res.data]
                    }
                    // 富文本
                    else if (this.upType == 3) {
                        let url = this.cutUrl(res.data[0].url);//获取图片路径
                        let quill = this.$refs.myQuillEditor.quill;//获取光标信息
                        let length = quill.getSelection().index;//获取当前光标位置
                        quill.insertEmbed(length, 'image', url);//在光标处插入图片
                        quill.setSelection(length + 1);//将光标移至图片后
                    }
                    this.$forceUpdate();
                }
            })
        },

        cutUrl(url) {
            let index = url.indexOf('?');
            return url.slice(0, index)
        },



    },
};
</script>
<style lang="scss" scoped>
.room {
    padding: 0.15rem;
    box-sizing: border-box;

    ::v-deep.input-with-select .el-input-group__append {
        background-color: #fff;

        .el-select .el-input {
            width: 90px;
        }
    }

    ::v-deep .el-input {
        width: 110%;
    }

    ::v-deep .el-input-group {
        width: 110%;
    }

    .upload-btn {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        width: 1rem;
        height: 1rem;
    }

    .upload-btn:hover {
        border-color: #409EFF;
    }

    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
    }

    .upload-img {
        display: inline-block;
        border: 1px dashed #d9d9d9;
        margin: 0 .1rem .1rem 0;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        position: relative;

        >img {
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }

    .tip {
        color: red;
        display: flex;
    }

    .imgs {
        display: flex;
        flex-wrap: wrap;
    }

    .img-del {
        width: 1rem;
        height: 1rem;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        margin: auto;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .5);
        visibility: hidden;

        >.el-icon-delete {
            font-size: 28px;
            color: #fff;
        }
    }

    .upload-img:hover .img-del {
        visibility: visible;
    }
}

.search_box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.15rem;
}

.table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
}
</style>
<style>
.long-width .el-form-item__content {
    width: 6rem;
}

.ql-container {
    min-height: 3rem;
}
</style>